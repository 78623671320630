@import "../Variables";

#contact-section {
  position: relative;
  margin-top: 104px;

  & .opening-hours {
    margin: 112px 0;

    color: $black;
    font-family: FreightDispProBook-Regular;
    font-size: 24px;
    letter-spacing: 0.23px;
    line-height: 30px;

    & .days {
      opacity: 0.4;
    }

    & .line {
      height: 1px;
      opacity: 0.1;
      background-color: #000000;
      margin: 24px 0;
    }

    & .times {
      text-align: right;
    }
  }

  & .contact {
    margin: 141px 0;
    position: absolute;

    & p:first-of-type {
      margin-top: 40px;
    }

    & a.google-maps {
      color: $black;
      font-family: FreightDispProBook-Regular;
      font-size: 20px;
      letter-spacing: 0.2px;
      line-height: 30px;
      border-bottom: 1px solid $black;
      padding-bottom: 2px;
      transition: padding-bottom ease $defaultTransitionDuration;

      &:hover {
        padding-bottom: 5px;
      }
    }
  }

  & .map-image {
    width: 100vw;
    height: auto;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 12.74%, rgba(255, 255, 255, 0) 100%);
    box-shadow: inset 0 -1px 0 0 #E5E5E5;
  }
}

@media only screen and (max-width: 1250px) {
  #contact-section {
    & .contact {
      margin: 24px 0 64px 0;
    }
  }
}

@media only screen and (max-width: 1200px) {
  #contact-section {
    & .opening-hours {
      margin: 32px 0 24px 0;

      font-size: 16px;
      letter-spacing: 0.2px;
      line-height: 20px;

      & .line {
        margin: 16px 0;
      }
    }

    & .contact {
      position: inherit;
    }

    & .map-image {
      display: none;
    }
  }
}

@media only screen and (max-width: 9910px){

  #contact-section-opening-hours{
    margin-bottom: 48px;
  }
}

