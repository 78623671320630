@import "../Variables";

$height: 112px;
$mobileHeight: 72px;

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: $height;
  background-color: $white;
  z-index: 10;
  transition: box-shadow .2s;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12);

  &.top {
    box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
  }

  & .header-container {
    display: table;
    width: 100%;
    height: $height;

    & .header-item {
      text-transform: uppercase;
      display: table-cell;
      vertical-align: middle;
      position: relative;

      &:last-of-type {
        text-align: right;

        & a {
          margin-left: 64px;
        }
      }

      & #logo {
        width: 128px;
      }

      &:hover > .link-to-scroll:not(:hover) {
        opacity: 0.5;
      }

      & .opened-batch {
        position: absolute;
        right: 0;
        bottom: 31px;
        color: $green;
        font-family: FreightSansProBook-Regular;
        font-size: 12px;
        letter-spacing: 0.4px;
        line-height: 14px;
      }

      &.mobile {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  header {
    & .header-container {
      & .header-item {
        &:last-of-type {

          & a {
            margin-left: 28px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  header {
    height: $mobileHeight;

    &.top {
      box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12);
    }

    & .header-container {
      height: $mobileHeight;

      & .header-item {

        & #logo {
          width: 96px;
        }

        &.desktop {
          display: none;
        }

        &.mobile {
          display: table-cell;
          text-align: right;
          width: 24px;

          & #menu-burger {
            position: relative;
            width: 24px;
            height: 24px;
            cursor: pointer;

            &.open {
              & div:first-of-type {
                transform: rotate(45deg);
                top: 12px;
              }

              & div:last-of-type {
                transform: rotate(-45deg);
                top: 12px;
              }
            }

            & div {
              position: absolute;
              height: 2px;
              width: 24px;
              background-color: $black;
              left: 0;
              transition: top ease $defaultTransitionDuration, transform ease $defaultTransitionDuration;

              &:first-of-type {
                top: 7px;
              }

              &:last-of-type {
                top: 14px;
              }
            }
          }

          & #mobile-menu {

            opacity: 0;
            pointer-events: none;
            transform-origin: top;
            transform: scale(.9);
            transition: opacity ease $defaultTransitionDuration, transform ease $defaultTransitionDuration;


            .link-to-scroll{
              height:48px;
            }

            &.open {
              opacity: 1;
              pointer-events: all;
              transform: scale(1);
            }

            position: fixed;
            top: $mobileHeight;
            left: 0;
            height: calc(100vh - #{$mobileHeight});
            width: 100vw;
            background-color: $white;

            & a {
              display: block;
              text-align: center;
              margin-top: 52px;
              color: $black;
              font-family: FreightDispProBook-Regular;
              font-size: 26px;
              letter-spacing: 0.75px;
              text-transform: capitalize;
              letter-spacing: 0.4px;
              line-height: 20px;

              &:first-of-type{
                margin-top: 80px;
              }
            }
          }
        }
      }
    }
  }
}
