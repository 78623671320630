@import "../../Variables";

.section-headline {
  margin-top: 16px;
  display: table;

  & div {
    display: table-cell;
    vertical-align: middle;
  }

  & .line div {
    height: 1px;
    width: 44px;
    border-radius: 1px;
    background-color: $black;
  }

  & .text div {
    color: $black;
    font-family: FreightSansProBook-Regular;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: uppercase;
    padding-left: 23px;
  }
}

.section-headline {
  & .line div {
    width: 44px;
    border-radius: 1px;
  }

  & .text div {
    font-size: 14px;
    letter-spacing: 1.4px;
    line-height: 15px;
  }
}
