@import "../Variables";

footer {
  & .footer-container {
    margin: 112px 0;
    display: table;
    width: 100%;

    & .footer-item {
      text-transform: uppercase;
      display: table-cell;
      vertical-align: middle;

      &:last-of-type {
        text-align: right;

        & a {
          margin-right: 24px;
        }

        & span {
          font-family: FreightSansProBook-Regular;
          font-size: 14px;
          letter-spacing: 1px;
          line-height: 18px;
          color: $black;
          margin-right: 24px;

          &:last-of-type {
            margin-right: 0;
          }
        }

      }

      & img {
        width: 128px;
        height: auto;
      }

      &:hover > .link-to-scroll:not(:hover) {
        opacity: 0.5;
      }
    }
  }
}

.impressum {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 1000;
  overflow-y: scroll;
  transition: opacity ease $defaultTransitionDuration;
  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  & .close-icon {
    position: fixed;
    cursor: pointer;
    right: 50px;
    top: 50px;
    transform: scale(.8);
    transition: ease transform $defaultTransitionDuration;

    &:hover {
      transform: scale(.9);
    }
  }

  & .content {
    padding: 100px;
    height: auto;
  }
}

@media only screen and (max-width: 1200px) {
  footer {
    border-top: $grey 1px solid;

    & .footer-container {
      margin: 64px 0;

      & .footer-item {
        display: block;

        & a, span {
          display: block;
          margin-top: 16px;
        }

        &:last-of-type {
          margin-top: 48px;
          text-align: left;
        }
      }
    }
  }

  .impressum {
    & .close-icon {
      right: 10px;
      top: 10px;
      transform: scale(.5);

      &:hover {
        transform: scale(.5);
      }
    }

    & .content {
      padding: 20px;
    }
  }
}
