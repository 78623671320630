html, body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 112px;
  background-color: white;
  z-index: 10;
  -webkit-transition: box-shadow .2s;
  transition: box-shadow .2s;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12); }
  header.top {
    box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0); }
  header .header-container {
    display: table;
    width: 100%;
    height: 112px; }
    header .header-container .header-item {
      text-transform: uppercase;
      display: table-cell;
      vertical-align: middle;
      position: relative; }
      header .header-container .header-item:last-of-type {
        text-align: right; }
        header .header-container .header-item:last-of-type a {
          margin-left: 64px; }
      header .header-container .header-item #logo {
        width: 128px; }
      header .header-container .header-item:hover > .link-to-scroll:not(:hover) {
        opacity: 0.5; }
      header .header-container .header-item .opened-batch {
        position: absolute;
        right: 0;
        bottom: 31px;
        color: #007B2D;
        font-family: FreightSansProBook-Regular;
        font-size: 12px;
        letter-spacing: 0.4px;
        line-height: 14px; }
      header .header-container .header-item.mobile {
        display: none; }

@media only screen and (max-width: 1200px) {
  header .header-container .header-item:last-of-type a {
    margin-left: 28px; } }

@media only screen and (max-width: 991px) {
  header {
    height: 72px; }
    header.top {
      box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12); }
    header .header-container {
      height: 72px; }
      header .header-container .header-item #logo {
        width: 96px; }
      header .header-container .header-item.desktop {
        display: none; }
      header .header-container .header-item.mobile {
        display: table-cell;
        text-align: right;
        width: 24px; }
        header .header-container .header-item.mobile #menu-burger {
          position: relative;
          width: 24px;
          height: 24px;
          cursor: pointer; }
          header .header-container .header-item.mobile #menu-burger.open div:first-of-type {
            -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
            top: 12px; }
          header .header-container .header-item.mobile #menu-burger.open div:last-of-type {
            -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
            top: 12px; }
          header .header-container .header-item.mobile #menu-burger div {
            position: absolute;
            height: 2px;
            width: 24px;
            background-color: black;
            left: 0;
            -webkit-transition: top ease 0.2s, -webkit-transform ease 0.2s;
            transition: top ease 0.2s, -webkit-transform ease 0.2s;
            transition: top ease 0.2s, transform ease 0.2s;
            transition: top ease 0.2s, transform ease 0.2s, -webkit-transform ease 0.2s; }
            header .header-container .header-item.mobile #menu-burger div:first-of-type {
              top: 7px; }
            header .header-container .header-item.mobile #menu-burger div:last-of-type {
              top: 14px; }
        header .header-container .header-item.mobile #mobile-menu {
          opacity: 0;
          pointer-events: none;
          -webkit-transform-origin: top;
                  transform-origin: top;
          -webkit-transform: scale(0.9);
                  transform: scale(0.9);
          -webkit-transition: opacity ease 0.2s, -webkit-transform ease 0.2s;
          transition: opacity ease 0.2s, -webkit-transform ease 0.2s;
          transition: opacity ease 0.2s, transform ease 0.2s;
          transition: opacity ease 0.2s, transform ease 0.2s, -webkit-transform ease 0.2s;
          position: fixed;
          top: 72px;
          left: 0;
          height: calc(100vh - 72px);
          width: 100vw;
          background-color: white; }
          header .header-container .header-item.mobile #mobile-menu .link-to-scroll {
            height: 48px; }
          header .header-container .header-item.mobile #mobile-menu.open {
            opacity: 1;
            pointer-events: all;
            -webkit-transform: scale(1);
                    transform: scale(1); }
          header .header-container .header-item.mobile #mobile-menu a {
            display: block;
            text-align: center;
            margin-top: 52px;
            color: black;
            font-family: FreightDispProBook-Regular;
            font-size: 26px;
            letter-spacing: 0.75px;
            text-transform: capitalize;
            letter-spacing: 0.4px;
            line-height: 20px; }
            header .header-container .header-item.mobile #mobile-menu a:first-of-type {
              margin-top: 80px; } }

.link-to-scroll {
  font-family: FreightSansProBook-Regular;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 18px;
  -webkit-transition: opacity ease .2s;
  transition: opacity ease .2s;
  color: black;
  position: relative; }
  .link-to-scroll.active span {
    opacity: 1; }
  .link-to-scroll span {
    opacity: 0;
    -webkit-transition: opacity ease 0.2s;
    transition: opacity ease 0.2s;
    width: 3px;
    height: 3px;
    border-radius: 1.5px;
    background-color: black;
    position: absolute;
    left: -11px;
    top: 8px; }

#landing-section {
  padding-top: 52vh;
  height: 100vh; }
  #landing-section .mode-banner {
    width: 240px;
    height: 240px;
    position: absolute;
    top: -300px;
    right: 100px;
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg); }
  #landing-section .headline {
    margin-top: 16px; }
  #landing-section .sub-content {
    font-family: FreightSansProBook-Regular;
    margin-top: 64px;
    font-size: 18px;
    letter-spacing: 0.4px;
    line-height: 20px; }
    #landing-section .sub-content div {
      float: left;
      margin-right: 66px; }
      #landing-section .sub-content div span {
        font-family: FreightDispProBold-Italic;
        font-size: 18px;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        padding-bottom: 4px;
        letter-spacing: 0.4px; }

@media only screen and (max-width: 991px) {
  #landing-section {
    padding-top: 40vh; }
    #landing-section .mode-banner {
      display: none; }
    #landing-section .sub-content {
      font-family: FreightSansProBook-Regular;
      margin-top: 16px;
      font-size: 12px;
      letter-spacing: 0.4px;
      line-height: 16px; }
      #landing-section .sub-content div {
        margin-top: 16px; }
        #landing-section .sub-content div span {
          font-size: 12px; } }

@media only screen and (max-width: 400px) {
  .section-headline .text div {
    font-size: 12px !important;
    letter-spacing: 0.8px !important; }
  #landing-section .headline {
    font-size: 32px; }
  #landing-section .sub-content div span {
    font-size: 15px; }
  #landing-section .sub-content {
    font-size: 14px; } }

@media only screen and (max-width: 330px) {
  #landing-section {
    padding-top: 30vh; } }

.section-headline {
  margin-top: 16px;
  display: table; }
  .section-headline div {
    display: table-cell;
    vertical-align: middle; }
  .section-headline .line div {
    height: 1px;
    width: 44px;
    border-radius: 1px;
    background-color: black; }
  .section-headline .text div {
    color: black;
    font-family: FreightSansProBook-Regular;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: uppercase;
    padding-left: 23px; }

.section-headline .line div {
  width: 44px;
  border-radius: 1px; }

.section-headline .text div {
  font-size: 14px;
  letter-spacing: 1.4px;
  line-height: 15px; }


@font-face {
  font-family: FreightDispProBook-Regular;
  src: url(/static/media/FreightDispProBook-Regular.86610772.ttf) format("truetype"), url(/static/media/FreightDispProBook-Regular.072a0354.woff) format("woff"), url(/static/media/FreightDispProBook-Regular.f6ff661a.woff2) format("woff2"); }

@font-face {
  font-family: FreightDispProBold-Italic;
  src: url(/static/media/FreightDispProBold-Italic.794404de.ttf) format("truetype"), url(/static/media/FreightDispProBold-Italic.9ce91da8.woff) format("woff"), url(/static/media/FreightDispProBold-Italic.c1927078.woff2) format("woff2"); }

@font-face {
  font-family: FreightSansProBook-Regular;
  font-weight: bold;
  src: url(/static/media/FreightSansProBook-Regular.f57b0750.ttf) format("truetype"), url(/static/media/FreightSansProBook-Regular.e28c2c4b.woff) format("woff"), url(/static/media/FreightSansProBook-Regular.2c378a6b.woff2) format("woff2"); }

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 24px;
  position: relative; }

h1 {
  color: black;
  font-family: FreightDispProBook-Regular;
  font-size: 80px;
  letter-spacing: 0.75px;
  line-height: 88px; }

h2 {
  color: black;
  font-family: FreightDispProBook-Regular;
  font-size: 72px;
  letter-spacing: 0.68px;
  line-height: 72px; }

h3 {
  color: black;
  font-family: FreightDispProBook-Regular;
  font-size: 56px;
  letter-spacing: 0.53px;
  line-height: 56px; }

h4 {
  color: black;
  font-family: FreightDispProBook-Regular;
  font-size: 32px;
  letter-spacing: 0.40px;
  line-height: 42px; }

p {
  color: black;
  font-family: FreightSansProBook-Regular;
  font-size: 17px;
  letter-spacing: 0.4px;
  line-height: 25px;
  margin-bottom: 32px; }

a,
a:hover,
a:visited {
  text-decoration: none;
  color: black; }

.bold {
  font-family: FreightDispProBold-Italic; }

.pointer {
  cursor: pointer; }

@media only screen and (max-width: 1200px) {
  h1 {
    font-size: 34px;
    line-height: 38px; }
  h2 {
    font-size: 34px;
    line-height: 38px;
    margin-top: 16px; }
  h3 {
    font-size: 34px;
    line-height: 38px; }
  h4 {
    font-size: 24px;
    line-height: 28px; } }

@media only screen and (max-width: 991px) {
  p .indent {
    display: none; }
  h3 {
    margin: 16px 0 24px 0; } }

#about-us-section #slider {
  margin: 85px 0 20px 0;
  height: 630px; }
  #about-us-section #slider .slider-image img {
    height: 630px;
    margin: 0 60px; }
  #about-us-section #slider .slider-image .sub-text {
    opacity: 0.4;
    color: black;
    font-family: FreightSansProBook-Regular;
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 24px;
    text-transform: uppercase;
    text-align: right;
    margin: 16px 60px 0 0; }

#about-us-section .slider-navigation {
  margin: 80px 120px 220px 120px;
  text-align: right; }
  #about-us-section .slider-navigation * {
    display: inline-block; }
  #about-us-section .slider-navigation span {
    color: black;
    font-family: FreightDispProBook-Regular;
    font-size: 24px;
    line-height: 24px; }
  #about-us-section .slider-navigation img {
    width: 24px;
    height: auto;
    margin: 0 16px;
    -webkit-transition: -webkit-transform ease 0.2s;
    transition: -webkit-transform ease 0.2s;
    transition: transform ease 0.2s;
    transition: transform ease 0.2s, -webkit-transform ease 0.2s;
    cursor: pointer; }
    #about-us-section .slider-navigation img:hover {
      -webkit-transform: translateX(-4px);
              transform: translateX(-4px); }
    #about-us-section .slider-navigation img:last-of-type {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
      #about-us-section .slider-navigation img:last-of-type:hover {
        -webkit-transform: rotate(180deg) translateX(-4px);
                transform: rotate(180deg) translateX(-4px); }

#about-us-section .quote {
  position: relative;
  padding-top: 200px; }
  #about-us-section .quote p {
    color: black;
    font-family: FreightDispProBook-Regular;
    font-size: 40px;
    letter-spacing: 0.3px;
    line-height: 47px; }
  #about-us-section .quote span {
    position: absolute;
    background-color: black;
    width: 10px;
    height: 1px;
    bottom: 10px;
    left: 0; }
  #about-us-section .quote div {
    margin: 16px 0 0 20px;
    font-family: FreightSansProBook-Regular;
    font-size: 16px; }

#about-us-section #about-us-image {
  margin-top: -350px; }
  #about-us-section #about-us-image img {
    height: auto;
    width: 100%; }

@media only screen and (max-width: 991px) {
  #about-us-section #slider {
    margin: 48px 0 20px 0;
    height: 350px; }
    #about-us-section #slider .slider-image img {
      height: 350px;
      margin: 0 10px;
      max-width: calc(100vw - 48px);
      object-fit: cover; }
    #about-us-section #slider .slider-image .sub-text {
      display: none; }
  #about-us-section .slider-navigation {
    margin: 0 0 60px 0;
    position: relative;
    height: 40px; }
    #about-us-section .slider-navigation span {
      position: absolute;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
    #about-us-section .slider-navigation img {
      position: absolute;
      top: 10px; }
      #about-us-section .slider-navigation img:first-of-type {
        left: 0; }
      #about-us-section .slider-navigation img:last-of-type {
        right: 0; }
  #about-us-section .quote {
    padding-top: 16px; }
    #about-us-section .quote p {
      font-size: 24px;
      letter-spacing: 0.2px;
      line-height: 30px; }
  #about-us-section #about-us-image {
    margin-top: 64px; }
    #about-us-section #about-us-image img {
      height: auto;
      width: 100%; } }

.image img {
  object-fit: contain;
  width: auto;
  height: 100%; }

.image .image-div {
  opacity: 0.4;
  color: black;
  font-family: FreightSansProBook-Regular;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 24px;
  text-transform: none;
  text-align: right;
  margin-top: 8px; }

@media only screen and (max-width: 991px) {
  .image .image-div {
    text-align: left;
    margin: 8px 0 0 24px;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 16px;
    text-transform: none; } }

#brands-section {
  margin: 190px 0 0 0;
  height: 2600px; }
  #brands-section .brands-logos {
    position: absolute;
    width: 100%;
    margin-top: 10px; }
    #brands-section .brands-logos img {
      width: auto;
      margin-top: 92px; }
      #brands-section .brands-logos img.marc-o-polo {
        height: 18px;
        margin-top: 97px; }
      #brands-section .brands-logos img.gustav {
        height: 29px; }
      #brands-section .brands-logos img.more-and-more {
        height: 14px;
        margin-top: 99px; }
      #brands-section .brands-logos img.blue-fire {
        height: 29px; }
      #brands-section .brands-logos img.esprit {
        height: 21px; }
      #brands-section .brands-logos img.jc-sophie {
        height: 21px; }
      #brands-section .brands-logos img.catalano-mode {
        height: 36px;
        -webkit-filter: invert(100%);
                filter: invert(100%); }
  #brands-section .brands-item-wrapper {
    position: relative; }
    #brands-section .brands-item-wrapper .brands-item {
      position: absolute; }
      #brands-section .brands-item-wrapper .brands-item img {
        width: 336px;
        height: auto; }
      #brands-section .brands-item-wrapper .brands-item .sub-text {
        opacity: 0.4;
        color: black;
        font-family: FreightSansProBook-Regular;
        font-size: 16px;
        letter-spacing: 0.4px;
        line-height: 24px;
        margin-top: 16px; }

@media only screen and (max-width: 1200px) {
  #brands-section .brands-item-wrapper .brands-item img {
    width: 313px;
    height: auto; } }

@media only screen and (max-width: 991px) {
  #brands-section {
    margin: 64px 0;
    height: auto; }
    #brands-section .brands-logos {
      position: inherit;
      margin-top: -20px; }
      #brands-section .brands-logos img {
        width: auto;
        margin-top: 44px; }
        #brands-section .brands-logos img.marc-o-polo {
          height: 14px;
          margin-top: 47px; }
        #brands-section .brands-logos img.gustav {
          height: 20px; }
        #brands-section .brands-logos img.more-and-more {
          height: 13px;
          margin-top: 48px; }
        #brands-section .brands-logos img.blue-fire {
          height: 17px; }
        #brands-section .brands-logos img.esprit {
          height: 15px; }
        #brands-section .brands-logos img.a-zone {
          height: 13px; }
    #brands-section .brands-item-wrapper {
      position: relative; }
      #brands-section .brands-item-wrapper .brands-item {
        position: inherit; }
        #brands-section .brands-item-wrapper .brands-item div {
          margin-top: 48px !important; }
        #brands-section .brands-item-wrapper .brands-item img {
          width: calc(100vw - 48px);
          max-width: 400px; }
        #brands-section .brands-item-wrapper .brands-item .sub-text {
          margin-top: 8px !important; } }

#contact-section {
  position: relative;
  margin-top: 104px; }
  #contact-section .opening-hours {
    margin: 112px 0;
    color: black;
    font-family: FreightDispProBook-Regular;
    font-size: 24px;
    letter-spacing: 0.23px;
    line-height: 30px; }
    #contact-section .opening-hours .days {
      opacity: 0.4; }
    #contact-section .opening-hours .line {
      height: 1px;
      opacity: 0.1;
      background-color: #000000;
      margin: 24px 0; }
    #contact-section .opening-hours .times {
      text-align: right; }
  #contact-section .contact {
    margin: 141px 0;
    position: absolute; }
    #contact-section .contact p:first-of-type {
      margin-top: 40px; }
    #contact-section .contact a.google-maps {
      color: black;
      font-family: FreightDispProBook-Regular;
      font-size: 20px;
      letter-spacing: 0.2px;
      line-height: 30px;
      border-bottom: 1px solid black;
      padding-bottom: 2px;
      -webkit-transition: padding-bottom ease 0.2s;
      transition: padding-bottom ease 0.2s; }
      #contact-section .contact a.google-maps:hover {
        padding-bottom: 5px; }
  #contact-section .map-image {
    width: 100vw;
    height: auto;
    background: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), color-stop(12.74%, rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0)));
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 12.74%, rgba(255, 255, 255, 0) 100%);
    box-shadow: inset 0 -1px 0 0 #E5E5E5; }

@media only screen and (max-width: 1250px) {
  #contact-section .contact {
    margin: 24px 0 64px 0; } }

@media only screen and (max-width: 1200px) {
  #contact-section .opening-hours {
    margin: 32px 0 24px 0;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 20px; }
    #contact-section .opening-hours .line {
      margin: 16px 0; }
  #contact-section .contact {
    position: inherit; }
  #contact-section .map-image {
    display: none; } }

@media only screen and (max-width: 9910px) {
  #contact-section-opening-hours {
    margin-bottom: 48px; } }

footer .footer-container {
  margin: 112px 0;
  display: table;
  width: 100%; }
  footer .footer-container .footer-item {
    text-transform: uppercase;
    display: table-cell;
    vertical-align: middle; }
    footer .footer-container .footer-item:last-of-type {
      text-align: right; }
      footer .footer-container .footer-item:last-of-type a {
        margin-right: 24px; }
      footer .footer-container .footer-item:last-of-type span {
        font-family: FreightSansProBook-Regular;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 18px;
        color: black;
        margin-right: 24px; }
        footer .footer-container .footer-item:last-of-type span:last-of-type {
          margin-right: 0; }
    footer .footer-container .footer-item img {
      width: 128px;
      height: auto; }
    footer .footer-container .footer-item:hover > .link-to-scroll:not(:hover) {
      opacity: 0.5; }

.impressum {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 1000;
  overflow-y: scroll;
  -webkit-transition: opacity ease 0.2s;
  transition: opacity ease 0.2s;
  opacity: 0;
  pointer-events: none; }
  .impressum.active {
    opacity: 1;
    pointer-events: all; }
  .impressum .close-icon {
    position: fixed;
    cursor: pointer;
    right: 50px;
    top: 50px;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transition: ease transform 0.2s;
    transition: ease transform 0.2s; }
    .impressum .close-icon:hover {
      -webkit-transform: scale(0.9);
              transform: scale(0.9); }
  .impressum .content {
    padding: 100px;
    height: auto; }

@media only screen and (max-width: 1200px) {
  footer {
    border-top: #E5E5E5 1px solid; }
    footer .footer-container {
      margin: 64px 0; }
      footer .footer-container .footer-item {
        display: block; }
        footer .footer-container .footer-item a, footer .footer-container .footer-item span {
          display: block;
          margin-top: 16px; }
        footer .footer-container .footer-item:last-of-type {
          margin-top: 48px;
          text-align: left; }
  .impressum .close-icon {
    right: 10px;
    top: 10px;
    -webkit-transform: scale(0.5);
            transform: scale(0.5); }
    .impressum .close-icon:hover {
      -webkit-transform: scale(0.5);
              transform: scale(0.5); }
  .impressum .content {
    padding: 20px; } }

