@import "../Variables";

$brandsLogosMargin: 92px;
$mobileBrandsLogosMargin: 44px;

#brands-section {
  margin: 190px 0 0 0;
  // If there are 5 images
  height: 2600px;
  // If there are 6 images
  // height: 2900px;

  & .brands-logos {
    position: absolute;
    width: 100%;
    margin-top: 10px;

    & img {
      width: auto;
      margin-top: $brandsLogosMargin;

      &.marc-o-polo {
        height: 18px;
        margin-top: $brandsLogosMargin + 5px;
      }

      &.gustav {
        height: 29px;
      }

      &.more-and-more {
        height: 14px;
        margin-top: $brandsLogosMargin + 7px;
      }

      &.blue-fire {
        height: 29px;
      }

      &.esprit {
        height: 21px;
      }

      &.jc-sophie {
        height: 21px;
      }

      &.catalano-mode {
        height: 36px;
        filter: invert(100%);
      }
    }
  }

  & .brands-item-wrapper {
    position: relative;

    & .brands-item {
      position: absolute;

      & img {
        width: 336px;
        height: auto;
      }

      & .sub-text {
        opacity: 0.4;
        color: $black;
        font-family: FreightSansProBook-Regular;
        font-size: 16px;
        letter-spacing: 0.4px;
        line-height: 24px;
        margin-top: 16px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  #brands-section {
    & .brands-item-wrapper {
      & .brands-item {
        & img {
          width: 313px;
          height: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  #brands-section {
    margin: 64px 0;
    height: auto;

    & .brands-logos {
      position: inherit;
      margin-top: -20px;

      & img {
        width: auto;
        margin-top: $mobileBrandsLogosMargin;

        &.marc-o-polo {
          height: 14px;
          margin-top: $mobileBrandsLogosMargin + 3px;
        }

        &.gustav {
          height: 20px;
        }

        &.more-and-more {
          height: 13px;
          margin-top: $mobileBrandsLogosMargin + 4px;
        }

        &.blue-fire {
          height: 17px;
        }

        &.esprit {
          height: 15px;
        }

        &.a-zone {
          height: 13px;
        }
      }
    }

    & .brands-item-wrapper {
      position: relative;

      & .brands-item {
        position: inherit;

        & div {
          margin-top: 48px !important;
        }

        & img {
          width: calc(100vw - 48px);
          max-width: 400px;
        }

        & .sub-text {
          margin-top: 8px !important;
        }
      }
    }
  }
}
