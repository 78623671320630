@import "../../Variables";

.image {
  & img {
    object-fit: contain;
    width: auto;
    height: 100%;
  }

  & .image-div {
    opacity: 0.4;
    color: $black;
    font-family: FreightSansProBook-Regular;
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 24px;
    text-transform: none;
    text-align: right;
    margin-top: 8px;
  }
}

@media only screen and (max-width: 991px) {
  .image {
    & .image-div {
      text-align: left;
      margin: 8px 0 0 24px;
      font-size: 14px;
      letter-spacing: 0.2px;
      line-height: 16px;
      text-transform: none;
    }
  }
}
