@import "../../Variables";

.link-to-scroll {
  font-family: FreightSansProBook-Regular;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 18px;
  transition: opacity ease .2s;
  color: $black;
  position: relative;

  &.active span {
    opacity: 1;
  }

  & span {
    opacity: 0;
    transition: opacity ease $defaultTransitionDuration;
    width: 3px;
    height: 3px;
    border-radius: 1.5px;
    background-color: $black;
    position: absolute;
    left: -11px;
    top: 8px;
  }
}
