@import "../Variables";

$sliderHeight: 630px;
$mobileSliderHeight: 350px;
$halfDistanceBetweenImages: 60px;
$mobileHalfDistanceBetweenImages: 10px;

#about-us-section {

  & #slider {
    margin: 85px 0 20px 0;
    height: $sliderHeight;

    & .slider-image {

      & img {
        height: $sliderHeight;
        margin: 0 $halfDistanceBetweenImages;
      }

      & .sub-text {
        opacity: 0.4;
        color: $black;
        font-family: FreightSansProBook-Regular;
        font-size: 16px;
        letter-spacing: 0.4px;
        line-height: 24px;
        text-transform: uppercase;
        text-align: right;
        margin: 16px $halfDistanceBetweenImages 0 0;
      }
    }
  }

  & .slider-navigation {
    margin: 80px 120px 220px 120px;
    text-align: right;

    & * {
      display: inline-block;
    }

    & span {
      color: $black;
      font-family: FreightDispProBook-Regular;
      font-size: 24px;
      line-height: 24px;
    }

    & img {
      width: 24px;
      height: auto;
      margin: 0 16px;
      transition: transform ease $defaultTransitionDuration;
      cursor: pointer;

      &:hover {
        transform: translateX(-4px);
      }

      &:last-of-type {
        transform: rotate(180deg);

        &:hover {
          transform: rotate(180deg) translateX(-4px);
        }
      }
    }
  }

  & .quote {
    position: relative;
    padding-top: 200px;

    & p {
      color: $black;
      font-family: FreightDispProBook-Regular;
      font-size: 40px;
      letter-spacing: 0.3px;
      line-height: 47px;
    }

    & span {
      position: absolute;
      background-color: $black;
      width: 10px;
      height: 1px;
      bottom: 10px;
      left: 0;
    }

    & div {
      margin: 16px 0 0 20px;
      font-family: FreightSansProBook-Regular ;
      font-size: 16px;
    }
  }

  & #about-us-image {
    margin-top: -350px;
    

    & img {
      height: auto;
      width: 100%;
      
    }
  }
}

@media only screen and (max-width: 991px) {
  #about-us-section {

    & #slider {
      margin: 48px 0 20px 0;
      height: $mobileSliderHeight;

      & .slider-image {

        & img {
          height: $mobileSliderHeight;
          margin: 0 $mobileHalfDistanceBetweenImages;
          max-width: calc(100vw - 48px);
          object-fit: cover;
        }

        & .sub-text {
          display: none;
        }
      }
    }

    & .slider-navigation {
      margin: 0 0 60px 0;
      position: relative;
      height: 40px;

      & span {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      & img {
        position: absolute;
        top: 10px;

        &:first-of-type {
          left: 0;
        }

        &:last-of-type {
          right: 0;
        }
      }
    }

    & .quote {
      padding-top: 16px;

      & p {
        font-size: 24px;
        letter-spacing: 0.2px;
        line-height: 30px;
      }
    }

    & #about-us-image {
      margin-top: 64px;

      & img {
        height: auto;
        width: 100%;
      }
    }
  }
}
