@import "Variables";

$wrapperWidth: 1440px;

@font-face {
  font-family: FreightDispProBook-Regular;
  src:  url('./assets/fonts/FreightDispProBook-Regular.ttf') format('truetype'),
        url('./assets/fonts/FreightDispProBook-Regular.woff') format('woff'),
        url('./assets/fonts/FreightDispProBook-Regular.woff2') format('woff2');
}

@font-face {
  font-family: FreightDispProBold-Italic;
  src:  url('./assets/fonts/FreightDispProBold-Italic.ttf') format('truetype'),
        url('./assets/fonts/FreightDispProBold-Italic.woff') format('woff'),
        url('./assets/fonts/FreightDispProBold-Italic.woff2') format('woff2');
}

@font-face {
  font-family: FreightSansProBook-Regular;
  font-weight: bold;
  src:  url('./assets/fonts/FreightSansProBook-Regular.ttf') format('truetype'),
        url('./assets/fonts/FreightSansProBook-Regular.woff') format('woff'),
        url('./assets/fonts/FreightSansProBook-Regular.woff2') format('woff2');
}


.wrapper {
  max-width: $wrapperWidth;
  margin: 0 auto;
  padding: 0 24px;
  position: relative;
}

h1 {
  color: $black;
  font-family: FreightDispProBook-Regular;
  font-size: 80px;
  letter-spacing: 0.75px;
  line-height: 88px;
}

h2 {
  color: $black;
  font-family: FreightDispProBook-Regular;
  font-size: 72px;
  letter-spacing: 0.68px;
  line-height: 72px;
}

h3 {
  color: $black;
  font-family: FreightDispProBook-Regular;
  font-size: 56px;
  letter-spacing: 0.53px;
  line-height: 56px;
}

h4 {
  color: $black;
  font-family: FreightDispProBook-Regular;
  font-size: 32px;
  letter-spacing: 0.40px;
  line-height: 42px;
}

p {
  color: $black;
  font-family: FreightSansProBook-Regular;
  font-size: 17px;
  letter-spacing: 0.4px;
  line-height: 25px;
  margin-bottom: 32px;
}

a,
a:hover,
a:visited {
  text-decoration: none;
  color: $black;
}

.bold {
  font-family: FreightDispProBold-Italic;
}


.pointer {
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  h1 {
    font-size: 34px;
    line-height: 38px;
  }

  h2 {
    font-size: 34px;
    line-height: 38px;
    margin-top: 16px;
  }

  h3 {
    font-size: 34px;
    line-height: 38px;
  }

  h4 {
    font-size: 24px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 991px) {
  p .indent {
    display: none;
  }

  h3 {
    margin: 16px 0 24px 0;
  }
}
