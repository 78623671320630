#landing-section {
  padding-top: 52vh;
  height: 100vh;

  & .mode-banner {
    width: 240px;
    height: 240px;
    position: absolute;
    top: -300px;
    right: 100px;
    transform: rotate(10deg);
  }

  & .headline {
    margin-top: 16px;
  }

  & .sub-content {
    font-family: FreightSansProBook-Regular;
    margin-top: 64px;
    font-size: 18px;
    letter-spacing: 0.4px;
    line-height: 20px;

    div {
      float: left;
      margin-right: 66px;

      span {
        font-family: FreightDispProBold-Italic;
        font-size: 18px;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        padding-bottom: 4px;
        letter-spacing: 0.4px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  #landing-section {
    padding-top: 40vh;

    & .mode-banner {
      display: none;
    }

    & .sub-content {
      font-family: FreightSansProBook-Regular;
      margin-top: 16px;
      font-size: 12px;
      letter-spacing: 0.4px;
      line-height: 16px;

      div {
        margin-top: 16px;

        span {
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .section-headline .text div {
    font-size: 12px !important;
    letter-spacing: 0.8px !important;
  }
  #landing-section .headline {
    font-size: 32px;
  }

  #landing-section .sub-content div span {
    font-size: 15px;
  }

  #landing-section .sub-content {
    font-size: 14px;
  }
}

@media only screen and (max-width: 330px) {
  #landing-section {
    padding-top: 30vh;
  }
}
